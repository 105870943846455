import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import "./App.css";
import CheckoutForm from "./components/CheckoutForm";
import Conversation from "./components/Conversation/Conversation";
import Header from "./components/Header";
import Start from "./components/Start";
import Confirmation from "./components/Confirmation/Confirmation";
import Terms, { TermsEnum } from "./components/Terms";
import UnsubscribeForm from "./components/Unsubscribe/Unsubscribe";
import Footer from "./components/Footer";
import Login from "./components/Admin/Login";
import Admin from "./components/Admin/Admin";
import Callback from "./components/Admin/FortnoxCallback";
import AdminLayout from "./components/Admin/AdminLayout";
import AuthLayout from "./components/AuthLayout";
import CookieConsent from "./components/CookieConsent";


const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
ReactPixel.init('1208752210181978', undefined, options);

ReactPixel.pageView();

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <main className="main-content">
                <Start />
                <Conversation />
                <CheckoutForm />
              </main>
            }
          />
          <Route
            path="/confirmation/"
            element={<Confirmation />}
          />
          <Route
            path="/tos/"
            element={<Terms terms={TermsEnum.USER} initialOpen />}
          />
          <Route
            path="/kopvillkor/"
            element={<Terms terms={TermsEnum.PURCHASE} initialOpen />}
          />
          <Route
            path="/privacy/"
            element={<Terms terms={TermsEnum.PRIVACY} initialOpen />}
          />
          <Route
            path="/unsubscribe/"
            element={<UnsubscribeForm />}
          />
          <Route path="/" element={<AuthLayout />}>
            <Route path="login/" element={<Login />} />
            <Route
              path="admin/*"
              element={<AdminLayout />}
            >
              <Route path="" element={<Admin />} />
              <Route path="callback" element={<Callback />} />
            </Route>
          </Route>
        </Routes>
      </Router>
      <Footer />
      <CookieConsent />
    </div>
  );
}

export default App;